document.addEventListener("DOMContentLoaded", function() {
    const seuNovoBotao = document.getElementById('seuNovoBotao');
    const chatToggleButton = document.querySelector('button[data-spec="toggle-button"]');

    if (!seuNovoBotao) {
        console.log('Elemento "seuNovoBotao" não foi encontrado.');
    }

    if (!chatToggleButton) {
        console.log('Elemento "chatToggleButton" não foi encontrado.');
    }

    if (seuNovoBotao && chatToggleButton) {
        console.log('Elemento "seuNovoBotao" encontrado:', seuNovoBotao);
        console.log('Elemento "chatToggleButton" encontrado:', chatToggleButton);

        seuNovoBotao.addEventListener('click', function(event) {
            event.preventDefault(); // Evita o comportamento padrão de redirecionamento do link
            console.log('Clique no botão detectado');

            chatToggleButton.click(); // Simula um clique no botão de chat toggle
        });
    } else {
        console.log('Um ou ambos os elementos não foram encontrados.');
    }
});